/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/

#about-us {
  margin-top: 120px;
}

@media (max-width: 1000px) {
  #about-us {
    margin-top: 50px;
  }
}

.about-us .icon-box {
  text-align: center;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  font-family: "Gilroy-Regular", sans-serif;
}
.about-us .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #0d6efd;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.about-us .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}
.about-us .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fce5e5;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.about-us .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  font-family: "Gilroy-Regular", sans-serif;
}
.about-us .icon-box h4 a {
  color: black;
  text-decoration: none;
  font-family: "Gilroy-Regular", sans-serif;
}
.about-us .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.about-us .icon-box:hover {
  background: #0d6efd;
  border-color: #0d6efd;
  padding: 70px 20px 90px 20px;
}
.about-us .icon-box:hover .icon {
  background: #fff;
}
.about-us .icon-box:hover .icon i {
  color: #0d6efd;
}
.about-us .icon-box:hover .icon::before {
  background: #0d6efd;
}
.about-us .icon-box:hover h4 a,
.about-us .icon-box:hover p {
  color: #fff;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.about-us-title {
  text-align: center;
  padding: 30px 0;
  position: relative;

  font-family: "Gilroy-Regular", sans-serif;
}
.about-us-title h1 {
  font-size: 42px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #0d6efd;
  position: relative;
  z-index: 2;
  font-family: "Gilroy-Regular", sans-serif;
}
.about-us-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.about-us-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #695f5f;
}
@media (max-width: 575px) {
  .about-us-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .about-us-title span {
    font-size: 38px;
  }
}

.heading-story {
  text-align: center;
  color: #0d6efd;
  margin-bottom: 7%;
  font-size: 60px;
}

.our-story p {
  font-family: "Gilroy-Regular", sans-serif;
  text-align: justify;
  width: 90%;
}

.our-story .content p:last-child {
  margin-bottom: 0;
}
.features .content + .content {
  margin-top: 100px;
}
.features .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.features .content ul {
  list-style: none;
  padding: 0;
}
.features .content ul li {
  padding-bottom: 10px;
}
.features .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #3b4ef8;
}
.features .content p:last-child {
  margin-bottom: 0;
}

.about-image {
  max-width: 85%;
}

.name {
  color: #0d6efd;
}
.position {
  color: #0b5479;
}
