.footer-48201 {
  padding: 6rem 0 0 0;
  background-color: #043046;
  color: rgb(193 183 183);
  font-family: "Gilroy-Regular", sans-serif;
}

.footer-48201 .footer-site-logo {
  font-size: 1.5rem;
  color: #fff;
}

.footer-48201 .nav-links li {
  margin-bottom: 10px;
}

.footer-48201 .nav-links li a {
  color: rgb(193 183 183);
  text-decoration: none;
  margin-left: 15%;
}

.footer-48201 .nav-links li a:hover {
  color: #fff;
}

.footer-48201 .social li {
  display: inline-block;
}

.footer-48201 .social li a {
  color: rgb(193 183 183);
  padding: 7px;
}

.footer-48201 .social li a:hover {
  color: #fff;
}

.footer-48201 .copyright {
  border-top: 1px solid #666262;
}

.title {
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
  font-family: "Gilroy-Regular", sans-serif;
  margin-left: 42%;
}

.social i {
  margin-right: 8px;
}

.address {
  font-weight: 600;
  font-size: 18px;
}

.address i {
  margin-right: 8px;
}

.address ul {
  list-style-type: none;
}

.footer-48201 .nav-links .title {
  color: rgb(135 210 255 / 90%);
  font-size: 18px;
}

.footer-48201 h3 {
  color: rgb(135 210 255 / 90%);
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 50px;
  right: 40px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1;
}

.my-float {
  margin-top: 13px;
  margin-bottom: 10px;
  font-size: 35px;
}

@media screen and (max-width: 1025px) {
  .footer-48201 .nav-links li a {
    margin-left: 9%;
    font-size: 14px;
  }

  .footer-48201 h4 {
    margin-left: 4%;
  }
}

@media screen and (max-width: 769px) {
  .footer-48201 .nav-links li a {
    margin-left: 5%;
  }

  .footer-48201 h4 {
    margin-left: 4%;
  }
}

@media screen and (max-width: 767px) {
  .footer-48201 {
    padding: 1.8rem 0 0 0;
  }

  .accordion-btn {
    display: block;
    padding: 0.5rem 1.2rem;
    border: 1px solid #666262;
    color: white;
    position: relative;
  }

  .menu {
    overflow: hidden;
  }

  .menu-item {
    overflow: hidden;
  }

  .menu-item__sub {
    background: #043046;
    overflow: hidden;
    transition: max-height 0.3s;
    max-height: 0;
    margin-left: 5%;
  }

  .footer-48201 .nav-links li a {
    margin-right: 5%;
  }
}

.footer-dropdown {
  max-height: 10em;
}

.social-title {
  text-align: center;
}

@media (max-width: 769px) and (min-width: 768px) {
  .about-footer {
    min-width: 154px;
  }

  .payment-footer {
    min-width: 101px;
  }
}

@media (max-width: 1200px) and (min-width: 1026px) {
  .about-footer {
    min-width: 190px;
    margin-left: -5px;
  }

  .payment-footer {
    min-width: 101px;
  }
}

@media (max-width: 991px) and (min-width: 770px) {
  .about-footer {
    min-width: 161px;
    margin-left: -5px;
  }

  .payment-footer {
    min-width: 112px;
  }
}