.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0d6efd;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: black;
  line-height: 0;
}

.back-to-top:hover {
  background: #0d6efd;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 85px;
  transition: all 0.5s;
  z-index: 997;
  background: rgba(255, 255, 255, 0.8);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0 4px 2px -2px; */
  box-shadow: rgb(0 0 0 / 10%) 0 4px 2px 1px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

#header .logo h1 {
  font-size: 30px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: rgb(64, 52, 238);
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin-left: 50%;
  max-height: 60px;
}

.img {
  height: 70px;
}

#header .logo img {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

#header .logo:hover img {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 992px) {
  #header {
    height: 68px;
    padding-bottom: 8px;
  }

  #header .logo img {
    margin-left: 5%;
    margin-top: 9%;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
  left: 10%;
}

@media (max-width: 770px) {
  .navbar {
    left: 6%;
  }
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  right: 6%;
}

.navbar>ul>li+li {
  margin-left: 30px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  font-weight: 600;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
  text-decoration: none;
  font-family: "Gilroy-Regular", sans-serif;
  color: #414952;
  font-size: 15px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 3px;
  left: 0;
  background-color: #0d6efd;
  visibility: hidden;
  width: 0px;

  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  transform: scaleX(1);
  width: 100%;
}

.sub-transition {
  display: inline-block;
}

.sub-transition:after {
  display: block;
  content: "";
  /* border-bottom: solid 2px #003e7f; */
  border-bottom: solid 2px #00458d;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.sub-transition:hover:after {
  transform: scaleX(1);
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #003e7f;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
  color: #414952;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #003e7f;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #0d6efd;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

#header div nav .fa-times {
  top: 85px;
  right: 21%;
}

@media (max-width: 1024px) {
  .social-head {
    position: relative;
    left: 5%;
  }
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
    right: 35px;
    position: relative;
  }

  .navbar ul {
    display: none;
  }

  .social-head {
    display: none;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  #header div nav .fa-times {
    right: 20%;
  }
}

.navbar-mobile {
  position: fixed;
  /* overflow: hidden; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(16, 31, 29, 0.9);
  transition: 0.3s;
  z-index: 0;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 13%;
  bottom: 15px;
  left: 15px;
  padding: 10px 0px 220px 36px;
  background-color: #fff;
  /* overflow-y: auto; */
  transition: 0.3s;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile>ul>li+li {
  margin: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #21413c;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #0d6efd;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
  margin-left: 30px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #0d6efd;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

.social-head {
  margin-top: 18px;
}

.social-head li {
  display: inline-block;
  padding-right: 25px;
}

.mobile-height {
  height: 433px;
}

.course-dropdown-height {
  height: 550px;
}