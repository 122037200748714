/* Slider start */

.bgimage {
  padding-top: 150px;
  margin-top: 80px;
  width: 100%;
  height: 600px;
  background: url("/src/assets/pexels-fauxels-3183190.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
  background-attachment: fixed;
}

.tabcourse {
  font-size: 16px;
}

h1,
h3 {
  font-weight: 700;
}

.para>p {
  font-size: 18px;
}

.bgimage {
  color: white;
}

/* Override UGG site */
#main {
  width: 100%;
  padding: 0;
}

.content-asset p {
  margin: 0 auto;
}

.breadcrumb {
  display: none;
}

/* Helpers */
/**************************/
.margin-top-10 {
  padding-top: 10px;
}

.margin-bot-10 {
  padding-bottom: 10px;
}

/* Typography */
/**************************/
#parallax-world-of-ugg h1 {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  padding: 0;
  margin: 0;
}

#parallax-world-of-ugg h3 {
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  line-height: 0;
  font-weight: 400;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: black;
}

#parallax-world-of-ugg p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.first-character {
  font-weight: 400;
  float: left;
  font-size: 84px;
  line-height: 64px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  font-family: "Source Sans Pro", sans-serif;
}

.sc {
  color: #3b8595;
}

.ny {
  color: #3d3c3a;
}

.atw {
  color: #c48660;
}

/* Section - Title */
/**************************/
#parallax-world-of-ugg .title {
  background: white;
  padding: 60px;
  margin: 0 auto;
  text-align: center;
}

#parallax-world-of-ugg .title h1 {
  font-size: 35px;
  letter-spacing: 8px;
}

/* Section - Block */
/**************************/
#parallax-world-of-ugg .block {
  background: white;
  width: 720px;
  margin: 0 auto;
  text-align: justify;
}

#parallax-world-of-ugg .block-gray {
  background: #f2f2f2;
  padding: 60px;
}

#parallax-world-of-ugg .section-overlay-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}

/* Section - Parallax */

/* Extras */
/**************************/
#parallax-world-of-ugg .line-break {
  border-bottom: 1px solid black;
  width: 150px;
  margin: 0 auto;
}

.nvda {
  max-width: 32px;
  max-height: 32px;
  margin-top: 25px;
}

.jaws {
  max-width: 68px;
  margin-top: 28px;
}

/* Media Queries */
/**************************/
@media screen and (max-width: 959px) and (min-width: 768px) {
  #parallax-world-of-ugg .block {
    padding: 40px;
    width: 620px;
  }
}

@media screen and (max-width: 767px) {
  #parallax-world-of-ugg .block {
    padding: 30px;
    width: 420px;
  }

  #parallax-world-of-ugg h2 {
    font-size: 30px;
  }

  #parallax-world-of-ugg .block {
    padding: 30px;
  }

  #parallax-world-of-ugg .parallax-one,
  #parallax-world-of-ugg .parallax-two,
  #parallax-world-of-ugg .parallax-three {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 459px) {
  #parallax-world-of-ugg .block {
    padding: 30px 15px;
    width: 290px;
  }

  .nav-tabs .nav-link {
    font-size: 9px;
  }

  .heading1text {
    font-size: 22px;
  }

  .heading3text {
    font-size: 18px;
  }

  .para>p {
    font-size: 13px;
  }

  .bgimage {
    padding-top: 100px;
    margin-top: 70px;
    height: 570px;
  }
}

@media screen and (max-width: 768px) {
  .nav-tabs .nav-link {
    font-size: 14px;
  }

  .heading1text {
    font-size: 18px;
  }

  .heading3text {
    font-size: 16px;
  }

  .para>p {
    font-size: 15px;
  }

  .bgimage {
    padding-top: 100px;
    margin-top: 70px;
    height: 600px;
  }
}

/* Slider end */

/* accordion start */
.accord {
  background-color: #0072ea;
}

/* accordion end */

/* price start */

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
  background-color: #0072ea;
  color: white;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}

/* Price end */

/* tools start */

/* tools end */