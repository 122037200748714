.payment-image {
  width: 100%;
}
.discount h2 {
  font-weight: 700;
  font-family: 'Gilroy-Regular', sans-serif;
}

.tab-item {
  background-color: #e5e5e5;
  color: #414952;
  padding: 15px;
  font-size: 20px;
  border-radius: 0px 0px 10px 10px;
  font-family: 'Gilroy-Regular', sans-serif;
}

.nav-tabs .nav-link.active {
  color: #0072ea;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  border-top: 2px solid #0072ea;
}
.nav-tabs .nav-link {
  color: #414952;
  font-size: 22px;
  font-weight: 600;
}

.section-title {
  margin-top: 5%;
}

.link {
  font-size: 30px;
  font-family: 'Gilroy-Regular', sans-serif;
  color: rgb(126, 121, 117);
  text-align: center;
}

.link a {
  text-decoration: none;
}

.button {
  background-color: #e5e5e5;
  border: 2px solid #0072ea;
  color: #0072ea;
}
.button:hover {
  background-color: #0072ea;
  border-color: #0072ea;
}
.button:hover a {
  color: #fff;
}

.tab-item p strong {
  color: #414952;
}

.button a {
  text-decoration: none;
}
@media (max-width: 769px) {
  .discount h2 {
    font-weight: 700;
    width: 70%;

    margin-top: -13%;
    /* margin-left: 15%; */
  }
}

@media screen and (max-width: 700px) {
  .tab-menu li {
    font-size: 9px;
  }

  .tab-item {
    font-size: 11px;
    font-family: 'Gilroy-Regular', sans-serif;
  }

  .link {
    font-size: 11px;
  }

  .button {
    font-size: 11px;
  }
}

@media screen and (max-width: 450px) {
  .payment .nav-tabs .nav-link {
    font-size: 9px;
  }
}

@media screen and (max-width: 350px) {
  .payment .nav-tabs .nav-link {
    font-size: 7px;
  }
}
