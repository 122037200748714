.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid rgb(218, 226, 233);
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #585858;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e1f0fa;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3498db;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3498db;
}

@media (max-width: 769px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 25px;
  }
}

@media (max-width: 450px) {

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
  }
}

@media (max-width: 1780px) and (min-width: 1677px) {
  .testimonial-item:nth-child(1) {
    min-height: 252px;
  }
}

@media (max-width: 1625px) {
  .testimonial-item:nth-child(1) {
    min-height: 296px;
  }
}

@media (max-width: 1327px) and (min-width: 1233px) {
  .testimonial-item:nth-child(1) {
    min-height: 322px;
  }
}

@media (max-width: 1233px) and (min-width: 1201px) {
  .person {
    min-height: 345px !important;
  }
}