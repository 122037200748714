.top-line h1 {
  color: #fff;
  font-weight: 800;
  position: relative;
  font-size: 55px;
  font: bold 60px/1 "Gilroy";
}

.left {
  padding: 240px 0 80px;
  display: flex;
  background: linear-gradient(67.28deg, #3f95ef 0.48%, #0072ea 99.06%);
  text-align: center;
  position: relative;
  color: #ffffff;
}
.right {
  padding: 80px 0;
  background: linear-gradient(
    67deg,
    rgba(0, 114, 234, 1) 0%,
    rgba(63, 149, 239, 1) 100%
  );
  padding: 70px 48px;
}

.form-select {
  background-color: rgba(232, 232, 232, 0.6);
}

.inputRequired {
  color: red;
}
.formSuccess {
  color: #4ea74e;
  font-size: 70px;
  font-family: "Gilroy-Regular", sans-serif;
}

.success-box {
  margin-top: 300px;
  margin-bottom: 200px;
}
.success-box p {
  text-align: center;
  font-weight: 600;
  font-family: "Gilroy-Regular", sans-serif;
}

.formFailed {
  color: red;
  font-size: 25px;
  font-family: "Gilroy-Regular", sans-serif;
}
.registration-form {
  /* background: linear-gradient(
    67deg,
    rgba(0, 114, 234, 1) 0%,
    rgba(63, 149, 239, 1) 100%
  ); */
  padding: 86px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.check {
  padding: 19px 0 0 10px;
}

.cyber {
  display: inline;
}

.registration-form .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #222222;
  font-family: "Gilroy-Regular", sans-serif;
}
.registration-form .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555555;
  font-family: "Gilroy-Regular", sans-serif;
}

.registration-form .php-email-form {
  width: 100%;
  border-top: 3px solid #0d6efd;
  border-bottom: 3px solid #0d6efd;
  padding: 30px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}
.registration-form .php-email-form .form-group {
  padding-bottom: 8px;
  font-family: "Gilroy-Regular", sans-serif;
}
.registration-form .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
  font-family: "Gilroy-Regular", sans-serif;
}
.registration-form .php-email-form .error-message br + br {
  margin-top: 25px;
}
.registration-form .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #0d6efd;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-family: "Gilroy-Regular", sans-serif;
}
.registration-form .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.registration-form .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #0d6efd;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.registration-form .php-email-form input[type="text"],
.registration-form .php-email-form input[type="email"],
.registration-form .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  color: black;
  font-weight: 600;
  border-radius: 4px;
  font-family: "Gilroy-Regular", sans-serif;
  background: rgba(232, 232, 232, 0.6);
}
.registration-form .php-email-form input:focus,
.registration-form .php-email-form textarea:focus {
  border-color: #0d6efd;
}

.registration-form .php-email-form textarea {
  padding: 10px 12px;
}
.registration-form .php-email-form button[type="submit"] {
  background: #0d6efd;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 25px;
  font-family: "Gilroy-Regular", sans-serif;
}
.registration-form .php-email-form button[type="submit"]:hover {
  background: #09367a;
}

.registration-form .form-check .form-check-input {
  padding: 6px 6px;
  margin-top: 6px;
  border-radius: 15px;
  font-family: "Gilroy-Regular", sans-serif;
}

.radio {
  font-family: "Gilroy-Regular", sans-serif;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #fbfbfb;
  min-height: 40px;
}
.title-box {
  width: 65%;
  margin: 0 auto;
  font-family: "Gilroy-Regular", sans-serif;
}
@media (max-width: 1025px) and (min-width: 800px) {
  .title-box {
    width: 63%;
    margin: 0 auto;
  }
}
@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 66px;
  }
}
.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 300;
  font-family: "Gilroy-Regular", sans-serif;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: "Gilroy-Regular", sans-serif;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #3c3c3c;
  content: "/";
}
@media (max-width: 769px) {
  .title-box {
    width: 80%;
  }
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
  .title-box h1 {
    font-size: 52px;
  }
}

@media (max-width: 992px) {
  .registration-form {
    padding: 70px 0 0 0;
  }
}

@media (max-width: 1199px) {
  .registration-form {
    display: grid;
    grid-template-columns: 1fr;
  }
  .check {
    font-size: 11px;
  }
  .title-box h1 {
    width: 104%;
  }
}
@media (max-width: 426px) and (min-width: 400px) {
  .title-box h1 {
    font-size: 30px;
    width: 105%;
    margin-top: -110px;
  }
}

@media (max-width: 400px) {
  .registration-form {
    padding: 68px 0 0 0;
  }
  .left {
    padding: 100px 0 80px;
  }
  .right {
    padding: 70px 10px;
  }
  .check {
    font-size: 11px;
  }
}
@media (max-width: 375px) {
  .title-box h1 {
    font-size: 24px;
    width: 100%;
    margin-top: 0px;
  }
}

.css-qc6sy-singleValue {
  color: black;
  font-size: 15px;
  font-weight: 600;
}

.css-1s2u09g-control {
  background-color: rgba(232, 232, 232, 0.6) !important;
}
