.contact {
    /* padding: 180px */
    padding-top: 100px;
}

.contact .info-box {
    color: #444444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
    padding: 20px 0 30px 0;
}

.contact .info-box i {
    font-size: 32px;
    color: #106eea;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #b3d1fa;
}

.contact .info-box h3 {
    font-size: 20px;
    color: #777777;
    font-weight: 700;
    margin: 10px 0;
}

.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
    padding: 0px 30px 30px 30px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
    margin-bottom: 20px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #106eea;
}

.contact .php-email-form input {
    padding: 10px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
    background: #106eea;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #3b8af2;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.map {
    border: 0;
    width: 100%;
    height: 300px;
}

.message-success {
    color: #18d26e;
    text-align: center;
    margin-top: 10px;
}

.form-title h2{
  
    color: #0d6efd;
    font-size: 20px;
    margin: 0 0 15px;
     padding-top: 15px;
    padding-bottom: 5px;

    text-align: center;

}

.map-div {
    box-shadow: 0 0 30px rgb(214 215 216 / 40%);
    padding: 0px 30px 30px 30px;

}