.job-title {
    text-align: left;
    font-size: 2.7rem;
    font-family: "Gilroy-Regular", sans-serif;

}

.des {
     font-family: "Gilroy-Regular", sans-serif;
  text-align: justify;
}

.summary {
    text-align: start;
    font-weight: 600;
    font-family: "Gilroy-Regular", sans-serif;
}

.descontact {
    text-align: start;
}

.job-requirements {
    text-align: start;
    font-family: "Gilroy-Regular", sans-serif;
}