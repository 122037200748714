.client-slider-one {
  max-width: 36%;
  margin-left: 32%;
}

.Announcement {
  color: #0d6efd;
  font-weight: 700;
  font-family: "Gilroy-Regular", sans-serif;
}

.date {
  color: white;
  text-align: center;
  font-size: larger;
}

.btn.view {
  margin-top: 4%;
  font-family: "Gilroy-Regular", sans-serif;
  margin-left: 46%;
  padding: 10px 24px;
}

.text-white {
  color: white;
}

.heading {
  text-align: center;
}

.client-box .row {
  background-color: #f8f7ff;
}

.client-box .row .name-box {
  background-color: #0d6efd;
}

.name-box h1 {
  margin-top: 15%;
  font-size: 50px;
  text-align: center;
}

.client-box h2.heading {
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
}

.client-box {
  margin: 2% auto;
}

.detail {
  width: 92%;
  text-align: center;
  color: #4f555b;
}

/* brochure */

.face-button {
  height: 64px;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #0d6efd;
  overflow: hidden;
  border-radius: 35px;

  margin: auto;
  display: block;
  margin: 4% auto 0 auto;

  width: 316px;
}

.face-button .icon {
  margin-right: 6px;
}

.face-button .face-primary,
.face-button .face-secondary {
  display: block;
  padding: 0 32px;
  line-height: 64px;
  transition: margin 0.4s;
}

.face-button .face-primary {
  background-color: #0d6efd;
  color: #fff;
}

.face-button:hover {
  border: 3px solid #0d6efd;
}

.face-button:hover .face-primary {
  margin-top: -64px;
}

@media (max-width: 728px) {
  .btn.view {
    margin-left: 36%;
    margin-top: 10%;
    padding: 4px 13px;
  }

  .face-button {
    width: 224px;
    font-size: 16px;
    margin-top: 10%;
  }

  .face-button .face-primary,
  .face-button .face-secondary {
    padding: 0 0px;
  }
}

/* Mobile + Tablet  */
@media (max-width: 769px) {
  .heading {
    font-size: 18px;
    margin-top: 15px;
  }

  .detail {
    font-size: 11px;
    width: 100%;
  }

  .name-box h1 {
    font-size: 35px;
  }

  .date {
    font-size: 12px;
  }

  .btn.view {
    margin-left: 43%;
    margin-top: 10%;
    padding: 4px 13px;
  }

  .client-box h2.heading {
    font-size: 15px;
    padding-top: 0;
  }

  .client-slider-one {
    max-width: 50%;
    margin-left: 25%;
  }
}

/* Mobile  */

@media (max-width: 426px) {
  .client-slider-one {
    max-width: 90%;
    margin-left: 5%;
  }

  .btn.view {
    margin-left: 40%;
    margin-top: 10%;
    padding: 4px 13px;
  }
}