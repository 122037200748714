.heading {
  text-align: center;
  color: #0d6efd;
  font-family: "Gilroy-Regular", sans-serif;
}
.quotes {
  padding: 80px 0;
  background: url("./assets/background.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
}
.quotes::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(78, 150, 184, 0.9);
}
.quotes .section-header {
  margin-bottom: 40px;
}
.quotes .quotes-carousel,
.quotes .quotes-slider {
  overflow: hidden;
}
.quotes .quotes-item {
  text-align: center;
  color: #fff;
}
.quotes .quotes-item .quotes-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}
.quotes .quotes-item h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}
.quotes .quotes-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}
.quotes .quotes-item .quote-icon-left,
.quotes .quotes-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}
.quotes .quotes-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.quotes .quotes-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.quotes .quotes-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}
.quotes .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.quotes .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}
.quotes .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #49b5e7;
}
@media (min-width: 992px) {
  .quotes .quotes-item p {
    width: 80%;
  }
}
.clients .client-logo {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #0d6efd;
}
.clients .client-logo img {
  transition: all 0.3s ease-in-out;
}
.clients .client-logo:hover img {
  filter: none;
  transform: scale(1.1);
}
@media (min-width: 992px) {
  .quotes .quotes-item p {
    width: 80%;
  }
}
