/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  margin-top: 70px;
  padding: 60px 0;
}
#hero h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}
#hero .btn-get-started {
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 28px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #009cea;
  text-decoration: none;
}
#hero .btn-get-started:hover {
  background: #008bd1;
}
#hero .hero-img {
  text-align: center;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 992px) {
  #hero h1 {
    font-size: 32px;
    line-height: 40px;
  }
  #hero h2 {
    font-size: 20px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f7f8f9;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  padding: 0;
  margin-top: 40px;
}
.about .container {
  background-color: #f7f8f9;
  padding: 60px 50px;
}

@media (max-width: 992px) {
  .about .container {
    padding: 30px;
  }
}
.about .content h3 {
  font-weight: 600;
  font-size: 32px;
  color: #364146;
  font-family: "Gilroy-Regular", sans-serif;
}

.newspage {
  margin-top: 6% !important;
}

.news-link {
  font-size: 24px;
  text-decoration: none;
  margin-bottom: 25px;
  display: block;
}

.news-left {
  padding-left: 10%;
}

.announcement-detail {
  font-size: 23px;
  font-family: "Gilroy-Regular", sans-serif;
}
@media (max-width: 769px) {
  #hero .btn-get-started {
    margin-bottom: 10%;
    font-size: 10px;
    padding: 12px 22px;
  }
}

@media (max-width: 426px) {
  #hero .btn-get-started {
    font-family: "Gilroy-Regular", sans-serif;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 22px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #009cea;
    text-decoration: none;
    margin-bottom: 10%;
  }
}

/* Events */

.events {
      padding-top: 100px;
      padding-bottom: 0px;
      
}

.event-title {
  padding-bottom: 0px;
}



#review {
  padding-top: 0;
}
