/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-bottom: 10px;
}
.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5cb874;
}
.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  /* background-color: #f8fcf9; */
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #0d6efd;
  font-family: "Gilroy-Regular", sans-serif;
  margin-top: 10%;
}
.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #0072ea;
  bottom: 0;
  left: calc(50% - 20px);
}

.about-tag h2::after {
  background: #fff;
}
.about-tag h2 {
  color: #fff;
}
.section-title p {
  margin-bottom: 0;
  font-size: 18px;
  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
}
.section-paragraph {
  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  text-align: justify;
}

#about .container .about-tag h2 {
  color: #fff;
}

#about .about-container {
  background-color: #0072ea;
}
