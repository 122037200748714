#topbar {
      background: #0072EA;
      font-size: 16px;
      height: 40px;
      padding: 0;
}

#topbar .contact-info a {
      line-height: 0;
      color: #fff;
      transition: 0.3s;
      text-decoration: none;
}

#topbar .contact-info a:hover {
      color: #fff;
      transform: scale(1.10);
}

#topbar .contact-info i {
      color: #5cb874;
      line-height: 0;
      margin-right: 5px;
}

#topbar .contact-info .phone-icon {
      margin-left: 15px;
}

#topbar .social-links a {
      color: #fff;
      padding: 4px 12px;
      display: inline-block;
      line-height: 1px;
      transition: 0.3s;
}

#topbar .social-links a:hover {
      color: #ffff;
      transform: scale(1.15)
}
