/* courses start */


.courses {
    /* padding-top: 100px; */
    padding: 5% 25%;
}

.team .member {
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgba(34, 34, 34, 0.07);
    padding: 30px 20px;
    background: #fff;
}

.team .member img {
    max-width: 60%;
    border-radius: 50%;
    margin: 0 0 30px 0;
}

.team .member h3 {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 18px;
    min-height: 50px;
}

.team .member span {
    /* font-style: italic; */
    display: block;
    font-size: 16px;

}

.team .member .para {
    /* font-style: italic; */
    margin-top: 35px;

}

.team .member p {
    padding-top: 20px;
    font-size: 14px;
    font-style: italic;
    color: #585858;
    min-height: 150px;
}

.team .member .social {
    margin-top: 0px;
}

.team .member .social03 {
    margin-top: 0px;
}

.team .member .social .icon {
    padding-right: 4px;
}


.team .member .social a {
    color: #919191;
    transition: 0.3s;
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

.detail-button {
    margin-top: 20px;
}


.btn {
    border-radius: 50px;
    padding: 5px 15px;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {}

@media only screen and (max-width: 1399px) and (min-width: 1200px) {}

@media only screen and (max-width: 2559px) and (min-width: 1400px) {

    .team .member p {
        min-height: 110px;
    }
}



@media only screen and (max-width: 1199px) and (min-width: 1024px) {

    .team .member h4 {
        min-height: 65px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {

    .team .member h4 {

        min-height: 65px;
    }

    .team .member p {

        min-height: 205px;
    }

}