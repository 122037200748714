/* Feature Box
---------------------*/
.feature-box-1 {
  padding: 32px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  margin: 15px 0;
  position: relative;
  min-height: 335px;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  top: 0;
}

.feature-box-1 * {
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}

.feature-box-1 .icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  background: #0072ea;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;

  font-size: 27px;
  margin: 0 auto;
  margin-bottom: 22px;
}

.feature-box-1 .icon i {
  line-height: 70px;
}

.feature-box-1 h3 {
  color: #20247b;
  font-weight: 600;
  font-size: 20px;
}

.feature-box-1 p {
  margin: 0;
}

.feature-box-1:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-radius: 10px;
  width: 0;
  background: #20247b;
  z-index: -1;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}

.feature-box-1:hover {
  top: -5px;
}

.feature-box-1:hover h3 {
  color: #ffffff;
}

.feature-box-1:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.feature-box-1:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  left: 0;
  right: auto;
}

.section {
  position: relative;
}

.section-title {
  padding-bottom: 45px;
}

.section-title h2 {
  font-weight: 700;
  color: #0d6efd;
  font-size: 45px;
  margin: 0 0 15px;
  padding-left: 15px;
}

@media screen and (max-width: 769px) {
  .section-title h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 426px) {
  .section-title h2 {
    font-size: 20px;
  }
}