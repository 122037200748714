.contact .info {
  border-top: 3px solid #0072ea;
  border-bottom: 3px solid #0072ea;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .info i {
  font-size: 20px;
  color: #0072ea;
  float: left;
  width: 44px;
  height: 44px;
  background: #eaf6ed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #222222;
  font-family: "Gilroy-Regular", sans-serif;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555555;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #0072ea;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #0072ea;
  color: #fff;
}

.contact .trialform {
  width: 100%;
  border-top: 3px solid #0072ea;
  border-bottom: 3px solid #0072ea;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .trialform .form-group {
  padding-bottom: 8px;
}

.contact .trialform .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .trialform .error-message br + br {
  margin-top: 25px;
}

.contact .trialform .sent-message {
  display: none;
  color: #fff;
  background: #0072ea;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-family: "Gilroy-Regular", sans-serif;
}

.contact .trialform .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .trialform .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .trialform input,
.contact .trialform textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
  font-family: "Gilroy-Regular", sans-serif;
}

.contact .trialform input:focus,
.contact .trialform textarea:focus {
  border-color: #0072ea;
}

/* .contact .trialform input {
    height: 44px;
} */

.check {
  padding: 19px 0 0 10px;
}

.contact .trialform textarea {
  padding: 10px 12px;
}

.contact .trialform button[type="submit"] {
  background: #0072ea;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .trialform button[type="submit"]:hover {
  background: #0b4079;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input[type="text"],
input[type="email"],
textarea,
.form-control {
  background-color: #f0f0f0;
}

.formfield {
  border-radius: 25px;
}
